import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function decodePassword(encodedValue: string) {
    if (!encodedValue) {
        return null;
    } else {
        return atob(encodedValue.replace(/\./g, '='));
    }
}

export function encodePassword(decodedValue: string) {
    if (!decodedValue) {
        return null;
    } else {
        return btoa(decodedValue).replace(/\=/g, '.');
    }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function matchValidator(
    matchTo: string,
): ValidatorFn {
    return (control: AbstractControl):
        ValidationErrors | null => {
        return !!control.parent &&
        !!control.parent.value &&
        control.value ===
        (control.parent?.controls as any)[matchTo].value
            ? null
            : {matching: true};
    };
}
