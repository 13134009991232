import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {MarketplaceAuthRequest, MarketplaceAuthResponse} from "../../_model/marketplace-auth-request.model";
import {Observable, take, tap} from "rxjs";
import {Router} from "@angular/router";
import {SecureLocalStorageService} from "./secured-local-storage.service";
import {ForgotPassReq} from "../../_model/user.model";
import {decodePassword} from "../../_utils/password.util";
import {ChangePasswordResponse, PasswordChange} from "../../_model/password.model";

const CURRENT_USER_KEY = 'currentUser';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    redirectUrl = '';

    constructor(
        private http: HttpClient,
        private secureLocalStorageSvc: SecureLocalStorageService,
        private router: Router
    ) {
    }

    public login(request: MarketplaceAuthRequest): Observable<MarketplaceAuthResponse> {
        const url = `${environment.apiBaseUrl}/v4/marketplace/auth`;
        return this.http.post<MarketplaceAuthResponse>(url, request)
            .pipe(take(1), tap(response => this.setCurrentUser(response)));
    }

    public loginWithToken(token: string): Observable<MarketplaceAuthResponse> {
        const url = `${environment.apiBaseUrl}/v4/marketplace/auth-token?token=${token}`;
        return this.http.post<MarketplaceAuthResponse>(url, null)
            .pipe(take(1), tap(response => this.setCurrentUser(response)));
    }

    public forgotPassword(request: ForgotPassReq): Observable<void> {
        const url = `${environment.apiBaseUrl}/v4/marketplace/forgot-password`;
        return this.http.post<void>(url, request).pipe(take(1));
    }

    public validatePasswordReset(email: string, resetCode: string): Observable<any> {
        const postData = {
            email: decodePassword(email),
            resetCode: decodePassword(resetCode)
        };
        const url = `${environment.apiBaseUrl}/v4/marketplace/validate-password-reset`;
        return this.http.post<any>(url, postData).pipe(take(1));
    }

    public changePassword(req: PasswordChange): Observable<ChangePasswordResponse> {
        const url = `${environment.apiBaseUrl}/v4/marketplace/change-user-password`;
        return this.http.post<ChangePasswordResponse>(url, req).pipe(take(1));
    }

    public logout() {
        if (this.getCurrentUser?.userId) {
            this.logoutServiceCall().subscribe({
                next: () => this.logoutCallback()
            });
        } else {
            this.logoutCallback();
        }
    }

    public logoutCallback() {
        this.removeCurrentUser();
        this.router.navigate(['']);
    }

    public logoutServiceCall() {
        const userId = this.getCurrentUser.userId;
        const url = `${environment.apiBaseUrl}/v1/users/${userId}/logout`;
        return this.http.post(url, {}).pipe(take(1));
    }

    private setCurrentUser(res: MarketplaceAuthResponse) {
        this.logoutCallback(); // To clear existing user details.
        this.secureLocalStorageSvc.setItem<MarketplaceAuthResponse>(CURRENT_USER_KEY, res);
    }

    get getCurrentUser(): MarketplaceAuthResponse {
        try {
            return this.secureLocalStorageSvc.getItem(CURRENT_USER_KEY);
        } catch (e) {
            console.log('Error: Get current user ', e);
            this.logoutCallback();
            return null;
        }
    }

    removeCurrentUser() {
        this.secureLocalStorageSvc.removeItem(CURRENT_USER_KEY);
        this.secureLocalStorageSvc.clear();
    }

}
