import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SecureLocalStorageService {
  private secretKey = environment.storageSecretKey; // Use environment variables for production

  // Encrypt and store data in local storage
  setItem<T>(key: string, value: T): void {
    const encryptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        this.secretKey
    ).toString();
    localStorage.setItem(key, encryptedValue);
  }

  // Decrypt and retrieve data from local storage
  getItem<T>(key: string): T | null {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;

    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue) as T;
    } catch (error) {
      console.error('Error decrypting local storage item:', error);
      return null;
    }
  }

  // Remove an item from local storage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all local storage
  clear(): void {
    localStorage.clear();
  }
}
